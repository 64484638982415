<template>
  <el-select
    :value="selectValue"
    @input="changeValue"
    :size="size"
    :loading="loading"
    :loading-text="loadingText"
    :reserve-keyword="reserveKeyword"
    :multiple="multiple"
    :collapseTags="collapseTags"
    :clearable="clearable"
    :filterable="filterable"
    :disabled="disabled"
    placeholder="Please select"
    @change="handleChange"
    @visible-change="handleFocus"
    allow-create
    @clear="handleClear"
  >
    <el-option-group v-for="group in dataSource" :key="group[parentKey]" :label="group[labelProp]">
      <el-checkbox
        v-if="hasAll && multiple && group[children].length > 0"
        v-model="group.checked"
        class="ml15"
        @change="selectAll($event, group[parentKey])"
        >All</el-checkbox
      >
      <el-option
        v-for="item in group[children]"
        :key="item[valueProp]"
        :label="item[labelProp]"
        :value="item[valueProp]"
      >
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
  export default {
    name: 'GroupSelect',
    model: {
      prop: 'selectValue',
      event: 'input',
    },
    props: {
      selectValue: {
        required: true,
      },
      dataSource: {
        type: Array,
        default: () => [],
      },
      parentKey: {
        type: String,
        default: 'id',
      },
      labelProp: {
        type: String,
        default: 'label',
      },
      valueProp: {
        type: String,
        default: 'value',
      },
      children: {
        type: String,
        default: 'children',
      },
      hasAll: {
        type: Boolean,
        default: true,
      },
      size: {
        type: String,
        default: '',
      },
      reserveKeyword: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      loadingText: {
        type: String,
        default: 'Loading...',
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      collapseTags: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      disabled: { type: Boolean, default: false },
      filterable: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      changeValue(value) {
        this.$emit('input', value);
      },
      selectAll(val, id) {
        const curArr = this.dataSource.find((f) => f.id === id);
        const arr = curArr[this.children].map((m) => m[this.valueProp]);
        if (val) {
          arr.forEach((item) => {
            if (!this.selectValue.includes(item)) {
              this.selectValue.push(item);
            }
          });
        } else {
          this.selectValue.forEach((item, index) => {
            if (arr.includes(item)) {
              this.selectValue.splice(index, 1, '');
            }
          });
        }

        this.$emit(
          'input',
          this.selectValue.filter((f) => f !== '')
        );
        this.$emit(
          'change',
          this.selectValue.filter((f) => f !== '')
        );
      },
      handleFocus(val) {
        if (val) {
          this.$emit('focus', this.selectValue);
        }
      },
      handleClear() {
        this.$emit('clear', this.selectValue);
      },
      handleChange(val) {
        if (this.multiple && this.hasAll) {
          this.dataSource.forEach((item) => {
            const arr = item[this.children].map((m) => m[this.valueProp]);
            item.checked =
              arr.length > 0 &&
              arr.every((v) => {
                return val.some((s) => s === v);
              });
          });
        }

        this.$emit('change', val);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ml15 {
    margin-left: 18px;
  }
  ::v-deep .el-select__tags {
    width: 100%;
    min-height: 30px;
    overflow-y: auto;
    overflow-x: visible;
    margin-left: 5px;
    &::-webkit-scrollbar {
      width: 2px;
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #dcdfe6;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: #c0c4cc;
    }
  }
</style>
